import * as Sentry from "@sentry/browser";
import axios from "axios";
import ui from "@alpinejs/ui";
import focus from "@alpinejs/focus";
import morph from "@alpinejs/morph";
import Alpine from "alpinejs";

// utils
import "./utils/ConstantsVariables";
import "./utils/BrowserUpdate";
import "./utils/WidthRems";
import "./utils/Breakpoints";
import "./utils/Dragging";
import "./utils/LazyLoad";
import "./utils/HelperFunctions";

// Components
// import "./components/Accessibility";
import "./components/FilterBar";
import "./components/Gallery";
import "./components/jobPostings";
import "./components/Lightbox";
import "./components/Locations";
import "./components/Navigation";
import "./components/Splide";
import "./components/SitewideAlerts";
import "./components/Shop/AddressAutocomplete";
import "./components/Shop/CorporateOrderAddressAutocomplete";
import "./components/Shop/RegisterSignIn";

// Alpine components
import Account from "./components/Shop/Account";
import AccountAnonymous from "./components/Shop/AccountAnonymous";
import AddressForm from "./components/Shop/AddressForm";
import AddressFields from "./components/Shop/AddressFields";
import AddressModal from "./components/Shop/AddressModal";
import AddressRecommendationModal from "./components/Shop/AddressRecommendationModal";
import CommerceAddress from "./components/Shop/CommerceAddress";
import AddToCart from "./components/Shop/AddToCart";
import BuyEGiftCard from "./components/Shop/BuyEGiftCard";
import Cart from "./components/Shop/Cart";
import CartSlider from "./components/Shop/CartSlider";
import CartLineItem from "./components/Shop/CartLineItem";
import CouponCodes from "./components/Shop/CouponCodes";
import Flatpickr from "./components/Shop/Flatpickr";
import GiftCards from "./components/Shop/GiftCards";
import GiftMessagePreview from "./components/Shop/GiftMessagePreview";
import GiftCardBalance from "./components/Shop/GiftCardBalance";
import MiniCart from "./components/Shop/MiniCart";
import OrderAdjustments from "./components/Shop/OrderAdjustments";
import CancelOrder from "./components/Shop/CancelOrder";
import PaymentMethod from "./components/Shop/PaymentMethod";
import PickYourPieceModal from "./components/Shop/PickYourPieceModal";
import ProductFilterSlider from "./components/Shop/ProductFilterSlider";
import ThoughtBarModal from "./components/Shop/ThoughtBarModal";
import CorporateOrder from "./components/Shop/CorporateOrdering";
import { validate } from "./directives/Form";

if (import.meta.env.MODE === "production") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.MODE || "local",
        release: __COMMIT_HASH__,
    });
}

window.axios = axios;

// Alpine Setup
window.Alpine = Alpine;
Alpine.data("Account", Account);
Alpine.data("AccountAnonymous", AccountAnonymous);
Alpine.data("AddressForm", AddressForm);
Alpine.data("AddressFields", AddressFields);
Alpine.data("CommerceAddress", CommerceAddress);
Alpine.data("AddressRecommendationModal", AddressRecommendationModal);
Alpine.data("AddressModal", AddressModal);
Alpine.data("AddToCart", AddToCart);
Alpine.data("BuyEGiftCard", BuyEGiftCard);
Alpine.data("Cart", Cart);
Alpine.data("CartLineItem", CartLineItem);
Alpine.data("CartSlider", CartSlider);
Alpine.data("CouponCodeForm", CouponCodes);
Alpine.data("Flatpickr", Flatpickr);
Alpine.data("GiftCardForm", GiftCards);
Alpine.data("GiftMessagePreview", GiftMessagePreview);
Alpine.data("GiftCardBalance", GiftCardBalance);
Alpine.data("MiniCart", MiniCart);
Alpine.data("OrderAdjustments", OrderAdjustments);
Alpine.data("CancelOrder", CancelOrder);
Alpine.data("PaymentMethod", PaymentMethod);
Alpine.data("PickYourPieceModal", PickYourPieceModal);
Alpine.data("ProductFilterSlider", ProductFilterSlider);
Alpine.data("ThoughtBarModal", ThoughtBarModal);
Alpine.data("CorporateOrder", CorporateOrder);

Alpine.directive("validate", validate);

Alpine.plugin(focus);
Alpine.plugin(ui);
Alpine.plugin(morph);
Alpine.start();
